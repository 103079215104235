import { useFormik } from "formik";
import { ChangeEvent, FC, Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { UserService } from "../../services/users/userService";
import { loadUserFromLocalStorage } from "../../utils/jwt";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import Page from "../../layout/Page/Page";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import { ClientService } from "../../services/clients/clientService";
import InputPhone, { isValidPhoneNumber } from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";
import 'react-phone-number-input/style.css';
import Select from "../../components/bootstrap/forms/Select";
import AsyncImg from "../../components/AsyncImg";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import { validateCif, validateCifNif, validateNif } from "../../utils/ValidationCifNif";
import ContactDataModal from "./contact-data/contactDataModal";
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import { StrategyService } from "../../services/strategies/strategyService";
import { CategoryService } from "../../services/categories/categoryService";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    clientData?: ClientForm;
    profileImg?: any;
    isClient?: boolean;
}

export interface ClientForm {
    id?: string;
    name?: string;
    clientCategory?: any;
    clientContactPerson?: any;
    user?: any;
    clientOrigin?: any;
    firstName?: string;
    lastName?: string;
    address?: string;
    cifNif?: string;
    population?: string;
    province?: string;
    pc: string;
    telephone: string;
    telephoneAux?: string;
    cpname: string;
    cpfirst_name: string
    cplast_name: string;
    cpnif: string;
    cpemail: string;
    cpposition: string;
    classification: string;
    note?: string;
    email?: string;
}

const schema = yup.object({
    name: yup.string().matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s.0-9]+$/u, 'El nombre solo puede contener letras, números y espacios'),
    cifNif: yup.string().test('valid-cif-nif', 'El CIF/DNI no es válido', (value) => {
        if (typeof value === 'string') {
            return validateCifNif(value);
        } else {
            return true;
        }
    }),
    telephone: yup.string().required('El teléfono es obligatorio').test("valid-phone", "El teléfono no es válido", (value) => isValidPhoneNumber(value)),
    telephoneAux: yup.string(),
    cpname: yup.string().required('El nombre es obligatorio'),
    cpnif: yup.string().test('valid-nif', 'El NIF no es válido', (value) => value ? validateNif(value) : true),
    email: yup.string().email("Correo inválido").required("El correo electrónico es obligatorio"),
    pc: yup.string().matches(/^[0-9]+$/, 'El código postal solo puede contener números').min(1, 'El código postal no puede tener menos de 1 dígito').max(15, 'El código postal no puede tener más de 5 dígitos'),
});

const ClientForm: FC<CreateFormProps> = ({ isLoading, submit, clientData, profileImg, isClient }) => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = useContext(PrivilegeContext);
    const mode = clientData ? 'Editar' : 'Crear';

    const [modal, setModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [strategies, setStrategies] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [users, setUsers] = useState([]);
    const [foundValues, setFoundValues] = useState(null);
    const [selectedImage, setSelectedImage] = useState<any>(null);

    const clientInitialValues: ClientForm = {
        id: clientData?.id || '',
        name: clientData?.name || '',
        clientCategory: clientData?.clientCategory?.id || '',
        clientContactPerson: clientData?.clientContactPerson?.id || '',
        clientOrigin: clientData?.clientOrigin?.id || '',
        firstName: clientData?.firstName || '',
        lastName: clientData?.lastName || '',
        address: clientData?.address || '',
        cifNif: clientData?.cifNif || '',
        population: clientData?.population || '',
        province: clientData?.province || '',
        pc: clientData?.pc || '',
        user: clientData?.user?.id || '',
        telephone: clientData?.clientContactPerson?.telephone || '',
        telephoneAux: clientData?.clientContactPerson?.telephoneAux || '',
        cpname: clientData?.clientContactPerson?.name || '',
        cpfirst_name: clientData?.clientContactPerson?.firstName || '',
        cplast_name: clientData?.clientContactPerson?.lastName || '',
        cpnif: clientData?.clientContactPerson?.nif || '',
        cpemail: clientData?.clientContactPerson?.email || '',
        cpposition: clientData?.clientContactPerson?.position || '',
        classification: clientData?.classification || '',
        note: clientData?.note || '',
        email: clientData?.email || '',
    };

    const fetchUsers = async () => {
        const userService = new UserService();
        const response = (await userService.getUsers()).getResponseData();
        if (response.success) {
            const filteredUsers = response.data.users.map((user: { id: string, name: string, lastName: string }) => ({
                value: user.id,
                label: user.name + ' ' + (user.lastName || '')
            }));
            setUsers(filteredUsers);
        } else {
            toast.error(response.message);
        }
    };

    const fetchCategories = async () => {
        const categoryService = new CategoryService();
        const response = (await categoryService.listCategories()).getResponseData();
        if (response.success) {
            const filteredCategories = response.data.categories.map((category: { id: string, name: string }) => ({
                value: category.id,
                label: category.name
            }));
            setCategories(filteredCategories);
        } else {
            toast.error(response.message);
        }
    };

    const fetchStrategies = async () => {
        const strategyService = new StrategyService();
        const response = (await strategyService.lisStrategies()).getResponseData();
        if (response.success) {
            const filteredStrategies = response.data.origins.map((strategy: { id: string, name: string, description: string }) => ({
                value: strategy.id,
                label: strategy.name
            }));
            setStrategies(filteredStrategies);
        } else {
            toast.error(response.message);
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchUsers();
        fetchStrategies();
    }, []);

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        try {
            const clientService = new ClientService();
            const response = await clientService.editClientImg(id, file);

            const responseData = response.getResponseData();

            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Imagen actualizada');
                }, 100);
            }
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará la imagen de perfil',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const clientService = new ClientService();
                    const response = await clientService.deleteClientImg(id);

                    const responseData = response.getResponseData();

                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar la imagen");
                }
            }
        })
    };

    const formik = useFormik({
        initialValues: clientInitialValues,
        validationSchema: schema,
        onSubmit: values => {
            if (mode == 'Crear' && foundValues && !areValuesEqual(values, foundValues)) {
                values.clientContactPerson = "notFound";
            }
            if (mode == 'Crear' && !foundValues) {
                values.clientContactPerson = "notFound";
            }
            submit(values);
        }
    });

    const verifyClass = (inputFieldID: keyof ClientForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrors = (inputFieldID: keyof ClientForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    const _onCloseModal = (values?: any) => {
        setModal(false);
        if (values) {
            setFoundValues(values);
            toast.success('Contacto seleccionado');
            formik.setFieldValue('clientContactPerson', values.contactId);
            formik.setFieldValue('cpname', values.name);
            formik.setFieldValue('cpfirst_name', values.first_name);
            formik.setFieldValue('cplast_name', values.last_name);
            formik.setFieldValue('cpnif', values.nif);
            formik.setFieldValue('cpemail', values.email);
            formik.setFieldValue('cpposition', values.position);
            formik.setFieldValue('telephone', values.telephone);
            formik.setFieldValue('telephoneAux', values.telephoneAux);
        } else {
            toast.error('Error al seleccionar el contacto');
        }
    }

    const areValuesEqual = (values: any, foundValues: any) => {
        // Comparar los valores relevantes
        return (
            values.cpname === foundValues.name &&
            values.cpfirst_name === foundValues.first_name &&
            values.cplast_name === foundValues.last_name &&
            values.cpnif === foundValues.nif &&
            values.cpemail === foundValues.email &&
            values.cpposition === foundValues.position &&
            values.telephone === foundValues.telephone &&
            values.telephoneAux === foundValues.telephoneAux
        );
    }

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off" className="row">
                <div className="col-md-6">
                    <Card stretch={true}>
                        <CardHeader>
                            <CardLabel icon='Person' iconColor='primary'>
                                <CardTitle>Datos del Cliente</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody isScrollable={false}>
                            <div className="row">
                                <div className="col-md-4">
                                    <FormGroup label='Nombre / Razón Social:'>
                                        <Input id='name' onChange={formik.handleChange} value={formik.values.name} className={verifyClass('name')} />
                                        {showErrors('name')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup label='Primer Apellido:'>
                                        <Input id='firstName' disabled={formik.values.cifNif != undefined ? validateCif(formik.values.cifNif) : false} onChange={formik.handleChange} value={formik.values.firstName} className={verifyClass('firstName')} />
                                        {showErrors('firstName')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup label='Segundo Apellido:'>
                                        <Input id='lastName' disabled={formik.values.cifNif != undefined ? validateCif(formik.values.cifNif) : false} onChange={formik.handleChange} value={formik.values.lastName} className={verifyClass('lastName')} />
                                        {showErrors('lastName')}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <FormGroup label='Domicilio:'>
                                        <Input id='address' onChange={formik.handleChange} value={formik.values.address} className={verifyClass('address')} />
                                        {showErrors('address')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-3">
                                    <FormGroup label='Población:'>
                                        <Input id='population' onChange={formik.handleChange} value={formik.values.population} className={verifyClass('population')} />
                                        {showErrors('population')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-3">
                                    <FormGroup label='Provincia:'>
                                        <Input id='province' onChange={formik.handleChange} value={formik.values.province} className={verifyClass('province')} />
                                        {showErrors('province')}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <FormGroup label='CIF / NIF:'>
                                        <Input id='cifNif' onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            formik.setFieldValue('cifNif', e.target.value);
                                            if (typeof formik.values.cifNif === 'string') {
                                                formik.values.firstName = validateCif(e.target.value) ? '' : formik.values.firstName;
                                                formik.values.lastName = validateCif(e.target.value) ? '' : formik.values.lastName;
                                            }
                                        }
                                        } value={formik.values.cifNif} className={verifyClass('cifNif')} />
                                        {showErrors('cifNif')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-2">
                                    <FormGroup label='Código Postal:'>
                                        <Input id='pc' type="number" onChange={formik.handleChange} value={formik.values.pc} className={verifyClass('pc')} />
                                        {showErrors('pc')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-7">
                                    <FormGroup requiredInputLabel label='Email:'>
                                        <Input id='email' type="email" onChange={formik.handleChange} value={formik.values.email} className={verifyClass('email')} />
                                        {showErrors('email')}
                                    </FormGroup>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-6">
                    <Card stretch={true}>
                        <CardHeader>
                            <CardLabel icon='ContactPhone' iconColor='primary'>
                                <CardTitle>Datos del Contacto</CardTitle>
                            </CardLabel>
                            {/* <Button type="button" color='secondary' onClick={() => { setModal(true); }}>
                                        Cargar Datos de Contacto
                                        <Icon
                                            icon='Download'
                                            className='ms-2'
                                        />
                                    </Button> */}
                        </CardHeader>
                        <CardBody isScrollable={false}>
                            <div className="row">
                                <div className="col-md-4">
                                    <FormGroup label='Nombre:' requiredInputLabel>
                                        <Input id='cpname' required onChange={formik.handleChange} value={formik.values.cpname} className={verifyClass('cpname')} />
                                        {showErrors('cpname')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup label='Primer Apellido:'>
                                        <Input id='cpfirst_name' onChange={formik.handleChange} value={formik.values.cpfirst_name} className={verifyClass('cpfirst_name')} />
                                        {showErrors('cpfirst_name')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-4">
                                    <FormGroup label='Segundo Apellido:'>
                                        <Input id='cplast_name' onChange={formik.handleChange} value={formik.values.cplast_name} className={verifyClass('cplast_name')} />
                                        {showErrors('cplast_name')}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <FormGroup label='NIF:' >
                                        <Input id='cpnif' onChange={formik.handleChange} value={formik.values.cpnif} className={verifyClass('cpnif')} />
                                        {showErrors('cpnif')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-3">
                                    <FormGroup label='Teléfono:' requiredInputLabel>
                                        <InputPhone id='telephone' onBlur={formik.handleBlur} labels={es} international defaultCountry="ES" onChange={e => { formik.setFieldValue('telephone', e) }} value={formik.values.telephone} className={verifyClass('telephone')} />
                                        {showErrors('telephone')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-3">
                                    <FormGroup label='Teléfono secundario:'>
                                        <InputPhone id='telephoneAux' onBlur={formik.handleBlur} labels={es} international defaultCountry="ES" onChange={e => { formik.setFieldValue('telephoneAux', e) }} value={formik.values.telephoneAux} className={verifyClass('telephoneAux')} />
                                        {showErrors('telephoneAux')}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <FormGroup label='Email:'>
                                        <Input id='cpemail' type="email" onChange={formik.handleChange} value={formik.values.cpemail} className={verifyClass('cpemail')} />
                                        {showErrors('cpemail')}
                                    </FormGroup>
                                </div>
                                <div className="col-md-6">
                                    <FormGroup label='En virtud de:'>
                                        <Input id='cpposition' onChange={formik.handleChange} value={formik.values.cpposition} className={verifyClass('cpposition')} />
                                        {showErrors('cpposition')}
                                    </FormGroup>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div className='col-md-12'>
                    <Card stretch={true}>
                        <CardHeader>
                            <CardLabel icon='Description' iconColor='primary'>
                                <CardTitle>Datos Adicionales y Estrategia</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody isScrollable={false}>
                            <div className="row d-flex justify-content-between">
                                <div className='col-md-2'>
                                    <FormGroup label='Categoria:'>
                                        <Select id='clientCategory' placeholder="Seleccione Categoria..." ariaLabel="Select Category" onChange={formik.handleChange} value={formik.values.clientCategory} className={verifyClass('clientCategory')} list={categories} />
                                        {showErrors('clientCategory')}
                                    </FormGroup>
                                </div>
                                <div className='col-md-5'>
                                    <FormGroup label='Estrategia:'>
                                        <Select id='clientOrigin' placeholder="Selecciones Estrategia..." ariaLabel="Select Strategy" onChange={formik.handleChange} value={formik.values.clientOrigin} className={verifyClass('clientOrigin')} list={strategies} />
                                        {showErrors('clientOrigin')}
                                    </FormGroup>
                                </div>
                                <div className={userCan("list_all_clients", "clients") ? "col-md-3" : "d-none"}>
                                    <FormGroup id='user' label='Usuario:'>
                                        <Select id="user" list={users} onChange={formik.handleChange} value={formik.values.user || ''} ariaLabel='Select User' placeholder='Elegir Usuario...' />
                                    </FormGroup>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div className={id ? 'col-md-6' : 'd-none'}>
                    <Card stretch>
                        <CardHeader className='rounded-1 mb-0'>
                            <CardLabel icon='PhotoCamera' iconColor='primary'>
                                <CardTitle>Imagen de perfil</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row align-items-center">
                                <div className='col-lg-3'>
                                    {selectedImage ?
                                        <img width={70} height={70} src={selectedImage} alt="selected" className='mx-auto d-block img-fluid mb-3 rounded' /> :

                                        profileImg
                                            ? <AsyncImg isBackground height="70px" width="70px" styles="rounded-circle" id={profileImg.id} />
                                            : <PlaceholderImage width={70} height={70} className='mx-auto d-block img-fluid mb-3 rounded' />
                                    }
                                </div>
                                <div className='col-lg-9 mt-4'>
                                    <div className='row g-4'>
                                        <div className='col-8'>
                                            <Input type='file' onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)} autoComplete='photo' />
                                        </div>
                                        <div className='col-4'>
                                            <Button color='dark' isLight icon='Delete' onClick={deleteImage}>Eliminar imagen</Button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-6">
                    <Card stretch>
                        <CardHeader className='rounded-1 mb-0'>
                            <CardLabel icon='Notes' iconColor='primary'>
                                <CardTitle>Observaciones</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <textarea
                                id='note'
                                onChange={formik.handleChange}
                                value={formik.values.note}
                                className={verifyClass('note')}
                                rows={4}
                                style={{
                                    borderRadius: '10px',
                                    boxShadow: '0 0 0 3px rgb(249, 230, 236)',
                                    backgroundColor: '#f8f9fa',
                                    width: '100%',
                                    padding: '15px',
                                    border: '0'
                                }}
                            />
                        </CardBody>
                    </Card>
                </div>
            </form>
        )
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} {isClient ? "Cliente" : "Contacto"}</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner /> : `${mode} ${isClient ? "Cliente" : "Contacto"}`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid'>
                {getContent()}
            </Page>

            {modal && <ContactDataModal isOpen={modal} setIsOpen={setModal} onClose={_onCloseModal} />}
        </Fragment >
    )
}

export default ClientForm;