import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import BudgetsList from "./budgets-list/BudgetsList";

const BudgetsListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider defaultLimit={100}>
                <ToastContainer />
                <BudgetsList />
            </FiltersProvider>
        </Fragment>
    )
}

export default BudgetsListWrapper;