import ReactApexChart from 'react-apexcharts';
import { bottom, } from '@popperjs/core';
import Card, { CardBody, CardHeader, CardTitle } from '../bootstrap/Card';

interface ChartDataItem {
    id: string
    name: string;
    color: string;
    count: number;
}

interface PieChartBoxProps {
    title: string;
    chartData: ChartDataItem[];
}

const PieChartBox: React.FC<PieChartBoxProps> = ({ title, chartData }) => {

    const series = chartData ? chartData.map(item => item.count || 0) : [];

    const options = {
        labels: chartData ? chartData.map(item => item.name || '') : [],
        colors: chartData ? chartData.map(item => item.color || '') : [],
        legend: {
            position: bottom,
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: number, opts: any) {
                return `${val.toFixed(2)}%`;
            },
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
    };

    return (
        <Card stretch>
            <CardHeader>
                <CardTitle className="text-center">{title}</CardTitle>
            </CardHeader>

            <CardBody className="p-0">
                {chartData && chartData.length > 0 ? (
                    <ReactApexChart
                        type="pie"
                        height={300}
                        options={options}
                        series={series}
                    />
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <p>No hay datos disponibles en este rango de fechas.</p>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default PieChartBox;