import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { Link } from "react-router-dom";
import { Status } from "../../../type/status-type";
import { BudgetService } from "../../../services/budgets/budgetService";
import { Budget, BudgetsApiResponse } from "../../../type/budgets-type";
import { StatusService } from "../../../services/status/statusService";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import { FinancesMenu } from "../../../menu";
import Card, { CardBody, CardFooter, CardHeader } from "../../../components/bootstrap/Card";
import { CardTitle } from "react-bootstrap";
import Spinner from "../../../components/bootstrap/Spinner";
import StatusesDropdown from "../../../components/dropdowns/StatusesDropdown";
import moment from "moment";

interface IBudgetsFilters {
  company?: string;
  client: string;
  classification?: string;
  status?: string;
  minAmount?: number;
  maxAmount?: number;
}

const budgetFilters: IBudgetsFilters = {
  company: '',
  client: '',
  classification: 'Consolidado',
  status: '',
  minAmount: 0,
  maxAmount: 9999999,
};

const LastBudgets = () => {

  const { filters, updateFilterOrder } = useFilters(budgetFilters, [], 1, 5);

  const budgetService = new BudgetService();

  const [budgetStatuses, setBudgetStatuses] = useState<Status[]>([]);

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    const response = await budgetService.listBudgets(filters);
    return response.getResponseData() as Budget;
  }, [filters]));

  const getStatusList = async (orientedTo: string): Promise<Status[] | undefined> => {
    const response = await (await (new StatusService()).listStatuses({ filter_filters: { oriented_to: orientedTo }, limit: 999999 })).getResponseData();
    if (response.success) {
      return response.data.statuses;
    } else {
      return [];
    }
  };

  const fetchStatuses = async () => {
    let budgetStatusList = await getStatusList("budget");
    setBudgetStatuses(budgetStatusList !== undefined ? budgetStatusList : []);
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  const getContent = () => {
    if (error) return <ErrorMessage error={error} />;

    if (data && data.budgets.length > 0) {
      let apiData = data as BudgetsApiResponse;

      return (
        <CustomTable
          data={apiData ? apiData.budgets : null}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "Estado",
              keyValue: "currentStatus",
              render: (item: any) => {
                return (
                  <div className="p-0" key={item.id}>
                    <StatusesDropdown
                      onChange={() => { refetch() }}
                      key={item.id + "-status"}
                      itemId={item.id}
                      statuses={budgetStatuses}
                      status={
                        item.budgetStatus != null
                          ? item.budgetStatus
                          : null
                      }
                      additionalInfo={
                        item?.budgetStatusDate
                          ? moment(item?.budgetStatusDate?.date).format("DD/MM/yyyy HH:mm")
                          : undefined
                      }
                      orientedTo={"budget"}
                    />
                  </div>
                );
              },
            },
            {
              name: "Identificador",
              keyValue: "code_identifier",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-primary fw-bold" >
                    <a href={`${FinancesMenu.finances.path}/${element.id}/vista`}>{element.codeIdentifier}</a>
                  </div>
                )
              },
            },
            {
              name: 'Dirección',
              keyValue: 'address',
              sortable: true,
              sortColumn: updateFilterOrder,
            }
          ]}
        />
      );
    }

    return <div className="text-center">No hay presupuestos</div>;
  };

  return (
    <Card stretch>
      <CardHeader>
        <CardTitle className="text-center">Últimos Presupuestos</CardTitle>
      </CardHeader>
      <CardBody className="p-0">
        <>{getContent()}</>
      </CardBody>
      <CardFooter className="d-flex justify-content-center">
        <>{data?.budgets?.length > 0 && <Link className="link" to={FinancesMenu.budget.path} style={{ color: '#ed291f', textDecoration: 'none' }}>Ver todos</Link>}</>
      </CardFooter>
    </Card>
  )
};

export default LastBudgets;