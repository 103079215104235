import { BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, Bar } from "recharts";
import Card, { CardHeader, CardTitle } from "../bootstrap/Card";
import DashBoardProfits from "../../pages/dashboard/dashBoard-cards/DashBoardsProfits";

interface ChartDataItem {
  currentProfits: number;
  expectedProfits: number;
  currentCosts: number;
  expectedCosts: number;
  year: number;
  month: number;
  day?: number;
}

type PieChartBoxProps = {
  dataProfits: ChartDataItem[];
  filters: any;
  dashboardDates: { startDate: string | null; endDate: string | null };
}
const BarChartBox: React.FC<PieChartBoxProps> = ({ dataProfits, filters, dashboardDates }) => {

  const chartData = dataProfits.map((item) => ({
    name: item.day === undefined ? `${item.month}` : `${item.day}`,
    ganr: item.currentProfits || 0,
    gans: item.expectedProfits || 0,
    gasc: item.currentCosts || 0,
    gasp: item.expectedCosts || 0,
  }));

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-center">Facturación</CardTitle>
      </CardHeader>

      <div className="row p-0">
        <div className="col-md-10">
          <ResponsiveContainer height={250}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip contentStyle={{ background: "#f2f2f2", borderRadius: "5px" }} labelStyle={{ display: "none" }} cursor={{ fill: "none" }} />
              <Legend wrapperStyle={{ color: 'black' }} />
              <Bar dataKey="ganr" fill="#0ca82e" name="Ganancias reales" />
              <Bar dataKey="gans" fill="#128bfc" name="Ganancias previstas" />
              <Bar dataKey="gasc" fill="#ed3705" name="Gastos reales" />
              <Bar dataKey="gasp" fill="#f7b500" name="Gastos previstos" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="col-md-2">
          <DashBoardProfits filters={filters} dashboardDates={dashboardDates} />
        </div>
      </div>
    </Card>
  );
};

export default BarChartBox;